import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CyphrIF from './components/CyphrIF';
import SignInSignUp from './components/SignInSignUp';
import './App.css';
import 'animate.css';
import { UserProvider } from './components/UserContext';

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<SignInSignUp />} />
            <Route path="/cyphrIF" element={<CyphrIF />} />
          </Routes>
        </div>
        </Router>
      </UserProvider>
  );
}

export default App;
