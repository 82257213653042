import React from 'react';
import '../App.css'

const Logo = ({ onLogoClick }) => {
  return (
    <svg 
      onClick={onLogoClick}
      xmlns="http://www.w3.org/2000/svg" 
      width="120" 
      height="120" 
      viewBox="0 0 2871.0369 2606.38"
    >
      {/* Replace below with your SVG paths */}
      <g id="c">
        <path 
                  d="m1435.5164,120c638.7749,0,1156.604,517.8295,1156.604,1156.6041s-517.8291,1156.604-1156.604,1156.604S278.9124,1915.3788,278.9124,1276.6041,796.7417,120,1435.5164,120m0-120c-86.5376,0-173.0564,8.7253-257.1528,25.9341-82.0212,16.7841-162.7002,41.8282-239.7959,74.437-75.6694,32.0059-148.6443,71.6136-216.8965,117.7242-67.5725,45.6511-131.1113,98.0741-188.8501,155.8135-57.7395,57.7389-110.1624,121.2777-155.8135,188.85-46.1106,68.2524-85.7183,141.2271-117.7241,216.8965-32.6089,77.0959-57.6531,157.7748-74.437,239.7959-17.209,84.0964-25.9341,170.6154-25.9341,257.153s8.7251,173.0564,25.9341,257.1528c16.7839,82.0212,41.8281,162.7002,74.437,239.7959,32.0059,75.6694,71.6135,148.6443,117.7241,216.8965,45.6511,67.5725,98.074,131.1113,155.8135,188.8501,57.7388,57.7395,121.2776,110.1624,188.8501,155.8135,68.2522,46.1106,141.2271,85.7183,216.8965,117.7241,77.0957,32.6089,157.7747,57.6531,239.7959,74.437,84.0964,17.209,170.6152,25.9341,257.1528,25.9341s173.0564-8.7251,257.1528-25.9341c82.0212-16.7839,162.7002-41.8281,239.7959-74.437,75.6694-32.0059,148.644-71.6135,216.8965-117.7241,67.5723-45.6511,131.1113-98.074,188.8501-155.8135,57.7393-57.7388,110.1621-121.2776,155.8135-188.8501,46.1108-68.2522,85.7183-141.2271,117.7241-216.8965,32.6089-77.0957,57.6528-157.7747,74.437-239.7959,17.209-84.0964,25.9341-170.6152,25.9341-257.1528s-8.7251-173.0565-25.9341-257.153c-16.7842-82.0211-41.8281-162.7-74.437-239.7959-32.0059-75.6694-71.6133-148.644-117.7241-216.8965-45.6514-67.5723-98.0742-131.1111-155.8135-188.85-57.7388-57.7394-121.2778-110.1624-188.8501-155.8135-68.2524-46.1106-141.2271-85.7183-216.8965-117.7242-77.0957-32.6088-157.7747-57.6529-239.7959-74.437C1608.5728,8.7253,1522.054,0,1435.5164,0h0Z" 
                  
           
          style={{ strokeWidth: "0px", fill: "#737e9e" }} 
        />
        <path 
          d="m1435.5183,1415.2767l313.77,543.4634h-627.54l313.77-543.4634m0-1295.2767l-560.8733,971.455-313.77,543.4633L0,2606.38h2871.0369l-560.875-971.4617-313.77-543.4633L1435.5183,120.0001h0Z" 
          style={{ strokeWidth: "0px", fill: "#737e9e"}}
        />
      </g>         
    </svg>
  );
};

export default Logo;
