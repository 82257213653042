// import React, { useState } from 'react';
// import './Forms.css';

// const CreateCyphr = ({ onCreate }) => {
//   const [cyphrName, setCyphrName] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onCreate(cyphrName);
//     setCyphrName('');
//   };

//   return (
//     <form onSubmit={handleSubmit} className="cif-container kb-form">
//       <input
//         type="text"
//         value={cyphrName}
//         onChange={(e) => setCyphrName(e.target.value)}
//         placeholder="Enter Cyphr name"
//         required
//       />
//       <button type="submit">Create Cyphr</button>
//     </form>
//   );
// };

// export default CreateCyphr;


// CreateCyphr.js

import React, { useState } from 'react';
import './Forms.css';

const API_ENDPOINT = 'https://t24pki1hb1.execute-api.us-west-2.amazonaws.com/Dev'; // Replace with your API Gateway URL

const CreateCyphr = ({ onCreate }) => {
  const [cyphrName, setCyphrName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          cyphrName,
          // Include other data as needed, like createdBy
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log(data); // Handle the response data as needed
      onCreate(cyphrName);
      setCyphrName('');
    } catch (error) {
      console.error('Error creating Cyphr:', error);
    }
  };

   return (
    <form onSubmit={handleSubmit} className="cif-container kb-form">
      <input
        type="text"
        value={cyphrName}
        onChange={(e) => setCyphrName(e.target.value)}
        placeholder="Enter Cyphr name"
        required
      />
      <button type="submit">Create Cyphr</button>
    </form>
  );
};

export default CreateCyphr;
