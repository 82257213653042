import React from 'react';
import { useGesture } from '@use-gesture/react';
import { useSpring, animated } from '@react-spring/web';
import Logo from './Logo';

const AnimatedLogo = ({ onSwipe, onTap }) => {
  const [style, api] = useSpring(() => ({ transform: 'scale(1)' }));

  const bind = useGesture({
    onDrag: ({ down }) => api.start({ transform: `scale(${down ? 1.1 : 1})` }),
    onDragEnd: (state) => {
      onSwipe && onSwipe(state);
    },
    onClick: () => {
      onTap && onTap();
    },
  });

  return (
    <animated.div {...bind()} style={{ ...style, touchAction: 'none', cursor: 'pointer'
    }} className="kb-rotation-eases-slow">
      <div className="kb-pulse-long-small">
        <Logo />
        </div>
    </animated.div>
  );
};

export default AnimatedLogo;
