// // UserContext.js
// import React, { createContext, useState, useEffect } from 'react';
// import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//     const [user, setUser] = useState(null);

//     useEffect(() => {
//         const loadUserFromStorage = () => {
//             const storedUser = localStorage.getItem('user');
//             if (storedUser) {
//                 setUser(JSON.parse(storedUser));
//             }
//         };

//         const checkCurrentUser = async () => {
//             try {
//                 const session = await fetchAuthSession();
//                 if (session) {
//                     const currentUser = await getCurrentUser();
//                     setUser(currentUser);
//                     localStorage.setItem('user', JSON.stringify(currentUser));
//                 }
//             } catch (error) {
//                 console.log('No current user:', error);
//                 localStorage.removeItem('user');
//             }
//         };

//         loadUserFromStorage();
//         checkCurrentUser();
//     }, []);

//     return (
//         <UserContext.Provider value={{ user, setUser }}>
//             {children}
//         </UserContext.Provider>
//     );
// };


import React, { createContext, useState, useEffect } from 'react';
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Attempt to load user from localStorage on initial render
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    useEffect(() => {
        const checkCurrentUser = async () => {
            try {
                const session = await fetchAuthSession();
                if (session) {
                    const currentUser = await getCurrentUser();
                    setUser(currentUser);
                    localStorage.setItem('user', JSON.stringify(currentUser));
                }
            } catch (error) {
                console.log('No current user:', error);
                setUser(null);
                localStorage.removeItem('user');
            }
        };

        if (!user) {
            checkCurrentUser();
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};


