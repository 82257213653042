// SignOut.js

import React from 'react';
import { signOut } from '@aws-amplify/auth';

const SignOut = ({ onSignOut }) => {
    const handleSignOut = async (global = false) => {
         localStorage.removeItem('user'); // Clear user session from local storage
        console.log("Sign out button clicked"); // Debugging line
        try {
            await signOut({ global });
            onSignOut && onSignOut();
             localStorage.removeItem('user'); // Clear user session from local storage
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className="cif-container kb-form">
            <button onClick={() => handleSignOut(false)}>Sign Out</button>
            <button onClick={() => handleSignOut(true)}>Sign Out from All Devices</button>
        </div>
    );
};

export default SignOut;
