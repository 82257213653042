import React, { useState } from 'react';


const SignIn = ({ onSignInSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

     const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    // Check if username or password is empty
    if (!username || !password) {
        setError("Username and password are required.");
        return;
    }

    try {
        await onSignInSuccess(username, password);
    } catch (error) {
        setError(error.message);
    }
};



    return (
        <div className="auth-form">
            <h2>Sign In</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <button type="submit">Sign In</button>
                {error && <p>{error}</p>}
            </form>
        </div>
    );
};

export default SignIn;
