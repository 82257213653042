import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, signUp, getCurrentUser } from '@aws-amplify/auth';
import { UserContext } from './UserContext';
import SignIn from './SignIn';
import SignUp from './SignUp';
import './Forms.css';

const AuthControl = () => {
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [isSignIn, setIsSignIn] = useState(true);

    const toggleForm = () => setIsSignIn(!isSignIn);

    const handleSignInSuccess = async (username, password) => {
        try {
            const currentUser = await getCurrentUser();
            if (currentUser) {
                setUser(currentUser);
                navigate('/cyphrIF');
            }
        } catch (error) {
            // Proceed with sign-in if no user is authenticated
            try {
                const signInResponse = await signIn({ username, password });
                if (signInResponse) {
                    const newUser = await getCurrentUser();
                    setUser(newUser);
                    navigate('/cyphrIF');
                }
            } catch (signInError) {
                console.error('Sign-in error:', signInError);
            }
        }
    };

    const handleSignUpSuccess = async (username, password, email, phone_number) => {
        try {
            const user = await signUp({
                username,
                password,
                attributes: {
                    email,
                    phone_number
                },
            });
            setUser(user); // Update user state in context
            navigate('/cyphrIF');
        } catch (error) {
            console.error('Sign-up error:', error);
        }
    };

    return (
        <div className="kb-form">
            {isSignIn ? <SignIn onSignInSuccess={handleSignInSuccess} /> : <SignUp onSignUpSuccess={handleSignUpSuccess} />}
            <button onClick={toggleForm}>
                {isSignIn ? "Don't have an account? Sign Up" : "Already have an account? Sign In"}
            </button>
        </div>
    );
};

export default AuthControl;


