import React, { useRef,useState } from 'react';
import { uploadData, isCancelError } from 'aws-amplify/storage';
import './FileUpload.css';
import './Forms.css';

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadTask, setUploadTask] = useState(null);
    const fileInputRef = useRef(null); // Reference to the file input

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setUploadProgress(0);
    };

    const handleUpload = async () => {
        if (file) {
            const task = uploadData({
                key: file.name,
                data: file,
                options: {
                    onProgress: ({ transferredBytes, totalBytes }) => {
                        setUploadProgress(Math.round((transferredBytes / totalBytes) * 100));
                    }
                }
            });

            setUploadTask(task);

            try {
                const result = await task.result;
                console.log('File uploaded successfully:', result.key);
                setFile(null); // Clear file after upload
                fileInputRef.current.value = ''; // Reset file input

                  // Wait for a short period before resetting the upload progress
            setTimeout(() => {
                setUploadProgress(0);
            }, 1227); // Adjust the timeout duration as needed
                
                
            } catch (error) {
                if (isCancelError(error)) {
                    console.log('Upload cancelled');
                } else {
                    console.error('Error uploading file:', error);
                }
            }
        }
    };

    const handlePause = () => {
        if (uploadTask) {
            uploadTask.pause();
        }
    };

    const handleResume = () => {
        if (uploadTask) {
            uploadTask.resume();
        }
    };

    const handleCancel = () => {
        if (uploadTask) {
            uploadTask.cancel();
        }
    };

  return (
    <div className="cif-container kb-form">
        <h2 className="file-upload-header">Upload File</h2>
        <input className="file-upload-input" type="file"  ref={fileInputRef} onChange={handleFileChange} />
        <div className="button-group">
            <button className="upload-button" onClick={handleUpload}>Upload</button>
            <button className="control-button" onClick={handlePause}>Pause</button>
            <button className="control-button" onClick={handleResume}>Resume</button>
            <button className="control-button" onClick={handleCancel}>Cancel</button>
          </div>
          <div className="progress-bar-wrapper">
            {uploadProgress > 0 && (
                <div className="progress-bar-container">
                    <div className="progress-bar-fill" style={{ width: `${uploadProgress}%` }}>
                        {uploadProgress} %
                    </div>
                </div>
            )}
        </div>
    </div>
);


};

export default FileUpload;
