import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify'; // Updated import statement
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

// Manually add configuration for the existing S3 bucket
// Amplify.configure({
//   Storage: {
//     AWSS3: {
//       bucket: 'mycyphr-usercontent81756-dev', // Replace with your bucket name
//       region: 'us-west-2', // Replace with your bucket region
//     }
//   }
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
