// SignInSignUp.js
import React, { useState } from 'react';
import AuthControl from './AuthControl';
import AnimatedLogo from './AnimatedLogo';
import './SignInSignUp.css';
import './Forms.css';

const SignInSignUp = () => {
 const handleUserChange = (user) => {
   // Update user state here, or perform other actions
   console.log("User changed");
  };

  const [showModal, setShowModal] = useState(false);

  

  const handleLogoTap = () => {
    setShowModal(true); // Show modal on logo tap
    console.log("Logo tapped");
  };

  
  const closeModal = () => {
    setShowModal(false); // Hide modal
  };

  return (
    <div className="SignInSignUp">
      <header className="App-header">
        <AnimatedLogo onTap={handleLogoTap} />
        <h1>myCyphr</h1>
      </header>
      {showModal && (
        <div className="modal-background" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <AuthControl onUserChange={handleUserChange}  />
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInSignUp;
