import React, { useContext } from 'react';
import { UserContext } from './UserContext';

const UserSession = () => {
    const { user } = useContext(UserContext);

    return (
        <div  className="cif-container">
            <h2>Date</h2>
            {user && (
                <div>
                    <p>Username: {user.username}</p>
                    {/* Display other user details as needed */}
                </div>
            )}
        </div>
    );
};

export default UserSession;
