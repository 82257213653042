// CIFLayout.js
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import SignOut from './SignOut';
import UserSession from './UserSession';
import Logo from './Logo';
import { UserContext } from './UserContext';
import CreateCyphr from './CreateCyphr';
import FileUpload from './FileUpload';
import CreateUserProfile from './CreateUserProfile';
import './CyphrIF.css';

const CIFLayout = ({ children }) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!user) {
            navigate('/');
        }
    }, [user, navigate]);

    const onSignOut = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className="cif-layout">
            <header className="cif-header">
                <Logo />
                <h1>myCyphr</h1>
                <UserSession user={user} className="cif-container"/>
                <SignOut onSignOut={onSignOut} />
            </header>
            <aside className="cif-sidebar">
                <CreateCyphr className="cif-container" />
                <CreateUserProfile />
                <FileUpload/>
            </aside>
            <main className="cif-content">
                {children}
            </main>
        </div>
    );
};

export default CIFLayout;
